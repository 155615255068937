@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,700,900");
@import url("https://fonts.googleapis.com/css?family=Roboto+Slab:400");
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  margin: 0px;
}

legend {
  font-weight: 700;
}

h1 {
  font-family: "Roboto", sans-serif;
  font-size: 57.93px;
  font-weight: 500;
  line-height: 65px;
  margin-top: 1em;
  margin-bottom: 1em;
}

h2 {
  font-family: "Roboto Slab", serif;
  font-size: 35.15625px;
  font-weight: 400;
  line-height: 42px;
  margin-top: 2em;
}

h3 {
  font-family: "Roboto", sans-serif;
  font-size: 28.125px;
  font-weight: 500;
  line-height: 33px;
  margin-top: 2em;
}

h4 {
  font-family: "Roboto", sans-serif;
  font-size: 22.5px;
  font-weight: 500;
  line-height: 27px;
  margin-top: 2em;
}

h5 {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  margin-top: 2em;
}

h6 {
  font-family: "Roboto", sans-serif;
  font-size: 14.4px;
  font-weight: 900;
  line-height: 20px;
  margin-top: 2em;
}

p {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  margin-top: 1em;
}

/* Enforce background colours */
* {
  -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
  color-adjust: exact !important; /*Firefox*/
}

@media screen and (max-width: 1440px) {
  html,
  body {
    font-size: 16px;
    line-height: 24px;
  }
  h1 {
    font-size: 39.0625px;
    line-height: 46px;
  }
  h2 {
    font-size: 31.25px;
    line-height: 37px;
  }
  h3 {
    font-size: 25px;
    line-height: 30px;
  }
  h4 {
    font-size: 20px;
    line-height: 24px;
  }
  h5 {
    font-size: 16px;
    line-height: 24px;
  }
  h6 {
    font-size: 12.8px;
    line-height: 19px;
  }
  p {
    font-size: 16px;
    line-height: 24px;
  }
}

a:link,
a:visited {
  color: #e2231b;
}

footer {
  clear: both;
  overflow: hidden;
  position: relative;
  z-index: 1;
  margin-top: 2em;
  font-size: 13px;
  line-height: 19px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

footer p {
  font-size: 13px;
  line-height: 19px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

.print-only {
  display: none !important;
}
.print-only-flex {
  display: none !important;
}
@media print {
  .print-only {
    display: block !important;
  }
  .print-only-flex {
    display: flex !important;
  }
  .do-not-print {
    display: none !important;
  }
  body {
    padding: 0rem !important;
    font-size: 14px !important;
    line-height: 16px !important;
  }
  p {
    font-size: 14px !important;
    line-height: 16px !important;
  }
  footer {
    break-inside: avoid !important;
    padding-right: 1rem !important;
  }
  h2 {
    margin-top: 0px !important;
    margin-bottom: 12px !important;
    font-size: 1rem !important;
    line-height: 1rem !important;
  }
  h3 {
    font-size: 20px !important;
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
  }
  .print-on-single-page {
    break-inside: avoid !important;
  }
  /* With flex - the break-inside of children isn't honoured sometimes*/
  .app-root {
    display: block !important;
  }
}
